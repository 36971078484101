import { Autocomplete, Dialog, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, MenuItem, Paper, Select, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Toast from "../componet/commonComponet/Toast";
import CloseIcon from "@mui/icons-material/Close";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import { baseURL, IsApprove, Url } from "../global";
import { BootstrapInput, ColorButton } from "../componet/customComponet/CustomElement";
const IBDashboard = (prop) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({
    withdrawal_from: "",
    payment_method: "",
    user_bank_id: "",
    upi_name: "",
    crypto_name: "",
    upi_crypto_ac_number: "",
    mt5_acc_no: "",
    amount: "",
    isLoder: false,
  });
  const [resData, setResData] = useState({});
  const [refresh, setRefresh] = React.useState(false);

  const [methodType, setMethodType] = useState({
    list: [],
    subList: [],
    mt5List: [],
    popLoaderMt5: true,
    popLoaderDepositMethod: true,
  });
  const [infoTrue, setinfoTrue] = useState({
    withdrawal_from: false,
    payment_method: false,
    user_bank_id: false,
    upi_name: false,
    crypto_name: false,
    upi_crypto_ac_number: false,
    mt5_acc_no: false,
    amount: false,
  });
  const [mainLoader, setMainLoader] = useState(true);
  const [info, setInfo] = useState({
    data: "",
    link: "",
  });

  const handleClose = () => {
    setOpen(false);
    setMethodType({
      list: [],
      subList: [],
      mt5List: [],
      popLoaderMt5: true,
      popLoaderDepositMethod: true,
    });
    setData({
      withdrawal_from: "",
      payment_method: "",
      user_bank_id: "",
      upi_name: "",
      crypto_name: "",
      upi_crypto_ac_number: "",
      mt5_acc_no: "",
      amount: "",
      isLoder: false,
    });
    setinfoTrue({
      withdrawal_from: false,
      payment_method: false,
      user_bank_id: false,
      upi_name: false,
      crypto_name: false,
      upi_crypto_ac_number: false,
      mt5_acc_no: false,
      amount: false,
    });
  };
  const menuItem = () => {
    if (data.payment_method == "Bank") {
      return (
        <FormControl className="w-100">
          {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
          <label
            htmlFor="bankAccount"
            className="text-info font-weight-bold form-label-head w-100 mt-4  required"
          >
            BANK ACCOUNT
          </label>
          <Select
            value={data.user_bank_id}
            name="user_bank_id"
            onChange={handleChange}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            input={<BootstrapInput />}
            className="mt-0 ml-0"
            style={{ width: "100%" }}
            onBlur={trueFalse}
          >
            <MenuItem value="">Select Option</MenuItem>

            {methodType.subList.map((item, index) => {
              if (item.payment_type == "Bank") {
                return item.payment_bank.map((item1, index1) => {
                  return (
                    <MenuItem value={item1.user_bank_id}>
                      {item1.bank_account_holder_name} {"("}
                      {item1.bank_account_number}
                      {")"}
                    </MenuItem>
                  );
                });
              }
              {
              }
            })}
          </Select>
          {methodType.subList.map((item, index) => {
            if (item.payment_type == "bank") {
              if (item.payment_bank.length == "0") {
                return (
                  <p>
                    No Bank Account added
                    <NavLink to="/bankAccounts">
                      <ColorButton
                        type="submit"
                        variant="contained"
                        size="small"
                        className="mt-2 text-capitalize"
                        sx={{ marginLeft: "10px" }}
                      >
                        Add new Bank Account
                      </ColorButton>
                    </NavLink>
                  </p>
                );
              }
            }
          })}
          {/* {methodType.subList[0].payment_bank.length == "0" ? (
              <p>
                No Bank Account added
                <NavLink to="/bankAccounts">
                  <ColorButton
                    type="submit"
                    variant="contained"
                    size="small"
                    className="mt-2 text-capitalize"
                    sx={{ marginLeft: "10px" }}
                  >
                    Add new Bank Account
                  </ColorButton>
                </NavLink>
              </p>
            ) : (
              ""
            )} */}
          {data.user_bank_id == "" && infoTrue.user_bank_id == true ? (
            <FormHelperText>Please Select Bank Account</FormHelperText>
          ) : (
            ""
          )}
        </FormControl>
      );
    } else if (data.payment_method == "UPI") {
      return (
        <FormControl className="py-4 w-100">
          {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
          <label
            htmlFor="upitype"
            className="text-info font-weight-bold form-label-head w-100  required"
          >
            UPI Type
          </label>
          <Select
            value={data.upi_name}
            name="upi_name"
            onChange={handleChange}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            input={<BootstrapInput />}
            className="mt-0 ml-0"
            style={{ width: "100%" }}
            onBlur={trueFalse}
          >
            <MenuItem value="">Select Option</MenuItem>
            {methodType.subList.map((item, index) => {
              if (item.payment_type == "UPI") {
                return item.payment_upi.map((item1, index1) => {
                  return <MenuItem value={item1}>{item1}</MenuItem>;
                });
              }
              {
              }
            })}
          </Select>
          {data.upi_name == "" && infoTrue.upi_name == true ? (
            <FormHelperText>Please Select UPI Type</FormHelperText>
          ) : (
            ""
          )}
          {data.upi_name ? (
            <>
              {" "}
              <label
                htmlFor="upi_crypto_ac_number"
                className="text-info font-weight-bold form-label-head w-100 mt-4 required"
              >
                UPI Id
              </label>
              <BootstrapInput
                name="upi_crypto_ac_number"
                type="text"
                value={data.upi_crypto_ac_number}
                onChange={handleChange}
                displayEmpty
                onBlur={trueFalse}
                inputProps={{
                  "aria-label": "Without label",
                }}
              />
              {data.upi_crypto_ac_number == "" &&
                infoTrue.upi_crypto_ac_number == true ? (
                <FormHelperText>Please Enter UPI Id</FormHelperText>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </FormControl>
      );
    } else if (data.payment_method == "Crypto") {
      return (
        <FormControl className="py-4 w-100">
          {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
          <label
            htmlFor="crypto_name"
            className="text-info font-weight-bold form-label-head w-100  required"
          >
            Crypto type
          </label>
          <Select
            value={data.crypto_name}
            name="crypto_name"
            onChange={handleChange}
            displayEmpty
            inputProps={{
              "aria-label": "Without label",
            }}
            input={<BootstrapInput />}
            className="mt-0 ml-0"
            style={{ width: "100%" }}
            onBlur={trueFalse}
          >
            <MenuItem value="">Select Option</MenuItem>
            {methodType.subList.map((item, index) => {
              if (item.payment_type == "Crypto") {
                return item.payment_crypto.map((item1, index1) => {
                  return <MenuItem value={item1.slug}>{item1.name}</MenuItem>;
                });
              }
            })}
          </Select>
          {data.crypto_name == "" && infoTrue.crypto_name == true ? (
            <FormHelperText>Please Select Crypto type</FormHelperText>
          ) : (
            ""
          )}
          {data.crypto_name ? (
            <>
              {" "}
              <label
                htmlFor="upi_crypto_ac_number"
                className="text-info font-weight-bold form-label-head w-100 mt-4 required"
              >
                Crypto Address
              </label>
              <BootstrapInput
                name="upi_crypto_ac_number"
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                onBlur={trueFalse}
              />
              {data.upi_crypto_ac_number == "" &&
                infoTrue.upi_crypto_ac_number == true ? (
                <FormHelperText>Please Enter Crypto Address</FormHelperText>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </FormControl>
      );
    }
  };
  const getDashboardData = async () => {
    const param = new FormData();
    // setMainLoader(true);
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("user_id", IsApprove.user_id);
      param.append("auth_key", IsApprove.auth);
    }
    await axios
      .post(`${Url}/ajaxfiles/get_dashboard.php`, param)
      .then((res) => {
        if (res.data.message == "Session has been expired") {
          localStorage.setItem("login", true);
          prop.setLogin("true");
          navigate("/");
        }
        if (res.data.ibData) {
          info.data = res.data.ibData;
          info.link = res.data.wallet_code;
          setInfo({ ...info });
          setMainLoader(false);
        }
      });
  };
  const trueFalse = (event) => {
    var { name, value } = event.target;
    setinfoTrue((prevalue) => {
      return {
        ...prevalue,
        [name]: true,
      };
    });
  };
  const fetchMT5AccountList = async () => {
    const param = new FormData();
    param.append("action", "get_mt5_ac_list_downline");
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("user_id", IsApprove.user_id);
      param.append("auth_key", IsApprove.auth);
    }
    await axios.post(`${Url}/ajaxfiles/account_list.php`, param).then((res) => {
      if (res.data.message == "Session has been expired") {
        navigate("/");
      }
      if (res.data.status == "error") {
        Toast("error", res.data.message);
      } else {
        methodType.mt5List = res.data.mt5_accounts;
        methodType.popLoaderMt5 = false;
        setMethodType({ ...methodType });
        if (res.data.mt5_accounts.length !== 0) {
          data.mt5_acc_no = res.data.mt5_accounts[0];
          setData({ ...data });
        }
      }
    });
  };
  const getMethodType = () => {
    const param = new FormData();
    param.append("action", "withdrawal_payment_methods");
    if (IsApprove !== "") {
      param.append("is_app", IsApprove.is_app);
      param.append("user_id", IsApprove.user_id);
      param.append("auth_key", IsApprove.auth);
    }
    axios.post(`${Url}/ajaxfiles/common_api.php`, param).then((res) => {
      if (res.data.message == "Session has been expired") {
        navigate("/");
      }
      if (res.data.status == "error") {
        Toast("error", res.data.message);
      } else {
        methodType.list = res.data.payment_method;
        methodType.subList = res.data.data;
        methodType.popLoaderDepositMethod = false;
        setMethodType({ ...methodType });
      }
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const onsubmit = () => {
    if (data.withdrawal_from == "") {
      Toast("error", "Select is required");
    } else if (
      data.withdrawal_from == "Withdraw" &&
      data.payment_method == ""
    ) {
      Toast("error", "Please Select Transaction Gateways");
    } else if (
      data.withdrawal_from == "Withdraw" &&
      data.payment_method == "Bank" &&
      data.user_bank_id == ""
    ) {
      Toast("error", "Please Select Bank Account");
    } else if (
      data.withdrawal_from == "Withdraw" &&
      data.payment_method == "UPI" &&
      data.upi_name == ""
    ) {
      Toast("error", "Please Select UPI Type");
    } else if (
      data.withdrawal_from == "Withdraw" &&
      data.payment_method == "UPI" &&
      data.upi_name &&
      data.upi_crypto_ac_number == ""
    ) {
      Toast("error", "Please Enter UPI Id");
    } else if (
      data.withdrawal_from == "Withdraw" &&
      data.payment_method == "Crypto" &&
      data.crypto_name == ""
    ) {
      Toast("error", "Please Select Crypto type");
    } else if (
      data.withdrawal_from == "Withdraw" &&
      data.payment_method == "Crypto" &&
      data.crypto_name &&
      data.upi_crypto_ac_number == ""
    ) {
      Toast("error", "Please Enter Crypto Address");
    } else if (
      data.withdrawal_from == "MT5" &&
      (data.mt5_acc_no == "" ||
        data.mt5_acc_no == null ||
        data.mt5_acc_no == undefined)
    ) {
      Toast("error", "Please Select MT5 Account");
    } else if (data.amount == "") {
      Toast("error", "Amount is required");
    } else if (data.amount > resData.balance) {
      Toast("error", "Insufficient fund Balance.");
    } else {
      const param = new FormData();
      if (IsApprove !== "") {
        param.append("is_app", IsApprove.is_app);
        param.append("user_id", IsApprove.user_id);
        param.append("auth_key", IsApprove.auth);
      }

      if (data.payment_method == "Bank" && data.withdrawal_from == "Withdraw") {
        param.append("user_bank_id", data.user_bank_id);
      } else if (
        data.payment_method == "UPI" &&
        data.withdrawal_from == "Withdraw"
      ) {
        param.append("upi_name", data.upi_name);
        param.append("upi_crypto_ac_number", data.upi_crypto_ac_number);
      } else if (
        data.payment_method == "Crypto" &&
        data.withdrawal_from == "Withdraw"
      ) {
        param.append("crypto_name", data.crypto_name);
        param.append("upi_crypto_ac_number", data.upi_crypto_ac_number);
      }

      if (data.withdrawal_from == "Withdraw") {
        param.append("payment_method", data.payment_method);
      }
      if (data.withdrawal_from == "MT5") {
        param.append("withdrawal_from", "MT5");
        param.append("mt5_acc_no", data.mt5_acc_no.mt5_acc_no);
      } else {
        param.append("withdrawal_from", data.withdrawal_from);
      }

      param.append("amount", data.amount);
      data.isLoder = true;
      setData({ ...data });
      axios
        .post(Url + "/ajaxfiles/insert_ib_commission_withdraw.php", param)
        .then((res) => {
          if (res.data.message == "Session has been expired") {
            navigate("/");
          }
          if (res.data.status == "error") {
            data.isLoder = false;
            setData({ ...data });
            Toast("error", res.data.message);
          } else {
            data.isLoder = false;
            setData({ ...data });
            setRefresh(!refresh);
            Toast("success", res.data.message);
            setOpen(false);
            setMethodType({
              list: [],
              subList: [],
              mt5List: [],
              popLoaderMt5: true,
              popLoaderDepositMethod: true,
            });
            setData({
              withdrawal_from: "",
              payment_method: "",
              user_bank_id: "",
              upi_name: "",
              crypto_name: "",
              upi_crypto_ac_number: "",
              mt5_acc_no: "",
              amount: "",
              isLoder: false,
            });
            setinfoTrue({
              withdrawal_from: false,
              payment_method: false,
              user_bank_id: false,
              upi_name: false,
              crypto_name: false,
              upi_crypto_ac_number: false,
              mt5_acc_no: false,
              amount: false,
            });
          }
        });
    }
  };

  useEffect(() => {
    getDashboardData();
    if (isMobile == true) {
    } else {
    }
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
    } else if (/android/i.test(userAgent)) {
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    } else {
    }

    // return "unknown";
  }, []);
  return (
    <div>
      <div className="app-content--inner">
        <div className="app-content--inner__wrapper mh-100-vh">
          {mainLoader == true ? (
            // <div className="loader1">
            //   <div className="clock">
            //     <div className="pointers"></div>
            //   </div>
            // </div>
            <div className="loader1">
              <span className="loader2"></span>
            </div>
          ) : (
            <div style={{ opacity: 1 }}>
              <Grid container>
                <Grid item sm={11}></Grid>
                <Grid item xl={1}></Grid>
                <Grid item xl={10} md={12} lg={12}>
                  <div>
                    <Grid container sx={{ justifyContent: "center" }}>
                      <Grid item md={12}>
                        <div className="row1 boxSection">
                          <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  {info.data.total_client}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  total client
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  ${info.data.total_deposit}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  total deposit
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">{info.data.total_lot}</h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  total lot
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  ${info.data.total_rebate}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  total rebate
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  ${info.data.total_rebate_availabel}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  rebate AVAILABLE
                                </p>
                              </div>
                            </div>
                          </div> */}

                          <div className="card padding-9 animate fadeLeft boxsize" style={{ display: info.data.pending_ib_withdraw_amount == 0 ? 'none' : 'block' }}>
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  ${info.data.pending_ib_withdraw_amount}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  pending withdraw 
                                </p>
                              </div>
                            </div>
                          </div>


                          {/* <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  ${info.data.total_rebate_withdraw}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  rebate withdraw
                                </p>
                              </div>
                            </div>
                          </div>{" "} */}
                          <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  {info.data?.ib_request}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  Ib Request
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="card padding-9 animate fadeLeft boxsize">
                            <div className="row">
                              <div className="col s12 m12 text-align-center">
                                <h5 className="mb-0">
                                  {info.data.total_subib}
                                </h5>
                                <p className="no-margin font-weight-700 text-uppercase">
                                  total subib
                                </p>
                              </div>
                            </div>
                          </div>

                         



                        </div>
                        <Paper
                          elevation={1}
                          style={{ borderRadius: "10px" }}
                          className="w-100 "
                        >
                          <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-3">
                            <h5 className="font-weight-bold mb-0 text-dark">
                              My Reference Links
                            </h5>
                            <ColorButton
                              className="depositFilter"
                              sx={{ marginLeft: "10px" }}
                              onClick={(e) => {
                                fetchMT5AccountList();
                                getMethodType();
                                setOpen(true);
                              }}
                            >
                              Withdrawal
                            </ColorButton>
                          </div>
                          <div className="divider"></div>
                          <div className="card-body position-relative">
                            <Grid
                              container
                              spacing={3}
                              style={{
                                marginLeft: "-12px",
                                marginRight: "-12px",
                              }}
                            >
                              <Grid item md={8}>
                                <FormControl>
                                  <label className="text-dark font-weight-bold form-label-head w-100">
                                    IB Link
                                  </label>
                                  <div className="sponsorlink-content-section">
                                    <label className="text-info font-weight-bold w-100">
                                      <a>
                                        {baseURL +
                                          `/register${prop?.permission?.ib_dashboard_link}`}
                                      </a>
                                    </label>
                                    <button
                                      className="copy_link"
                                      onClick={(e) => {
                                        navigator.clipboard
                                          .writeText(
                                            baseURL +
                                            `/register${prop?.permission?.ib_dashboard_link}`
                                          )
                                          .then(
                                            function () {
                                              Toast(
                                                "success",
                                                "The IB link has been successfully copying"
                                              );
                                            },
                                            function (err) {
                                              console.error(
                                                "Async: Could not copy text: ",
                                                err
                                              );
                                              Toast(
                                                "error",
                                                "The IB link Could not copy, Please try again"
                                              );
                                            }
                                          );
                                      }}
                                    >
                                      <span className="blinking">
                                        <i className="material-icons">
                                          content_copy
                                        </i>
                                      </span>
                                    </button>
                                  </div>
                                </FormControl>
                              </Grid>
                              <Grid item md={4}>
                                <FormControl>
                                  <label className="text-dark font-weight-bold form-label-head w-100">
                                    Referral Cilck
                                  </label>
                                  <div className="sponsorlink-content-section">
                                    <label className="text-info font-weight-bold w-100">
                                      {info.data.total_reffral_click}
                                    </label>
                                  </div>
                                </FormControl>
                              </Grid>
                              {/* <hr className="mt-2.5 mb-1"></hr>
                          <Grid item md={12}>
                            <FormControl>
                              <label className="text-dark font-weight-bold form-label-head w-100">
                                Sales Manager Link
                              </label>
                              <label className="text-info font-weight-bold w-100">
                              <a>{Url+'?salesmanagerId=272727'}</a>
                              </label>
                            </FormControl>
                          </Grid> */}
                            </Grid>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
        style={{
          opacity: "1",
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        }}
        PaperProps={{
          sx: {
            width: "25%",
            maxWidth: "768px",
            borderRadius: "10px",
            elevation: "24",
            class: "border border-bottom-0",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex align-items-center p-3"
          style={{ borderBottom: "none" }}
        >
          {/* <ConfirmationNumberOutlinedIcon className="text-primary" /> */}
          <h5 className="ml-3 w-100 text-start mt-2 mb-2 font-weight-bold">
            IB Withdrawal
          </h5>
          <CloseIcon
            onClick={() => {
              setMethodType({
                list: [],
                subList: [],
                mt5List: [],
                popLoaderMt5: true,
                popLoaderDepositMethod: true,
              });
              setData({
                withdrawal_from: "",
                payment_method: "",
                user_bank_id: "",
                upi_name: "",
                crypto_name: "",
                upi_crypto_ac_number: "",
                mt5_acc_no: "",
                amount: "",
                isLoder: false,
              });
              setinfoTrue({
                withdrawal_from: false,
                payment_method: false,
                user_bank_id: false,
                upi_name: false,
                crypto_name: false,
                upi_crypto_ac_number: false,
                mt5_acc_no: false,
                amount: false,
              });
              setOpen(false);
            }}
          />
        </DialogTitle>
        <DialogContent className="create-account-content ml-4">
          {methodType.popLoaderDepositMethod == true ||
            methodType.popLoaderMt5 == true ? (
            <>
              <div className="card-body position-relative pt-0 mr-4 get-mt5-account-details">
                <svg class="spinner" viewBox="0 0 50 50">
                  <circle
                    class="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke-width="5"
                  ></circle>
                </svg>
              </div>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                className="MuiGrid-justify-xs-space-between mt-2"
              >
                <FormControl
                  className="w-100 "
                  error={data.withdrawal_from == "" ? true : false}
                >
                  {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
                  <label
                    htmlFor="transactionGateway"
                    className="text-info font-weight-bold form-label-head w-100 required"
                  >
                    Select
                  </label>
                  <Select
                    value={data.withdrawal_from}
                    name="withdrawal_from"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    input={<BootstrapInput />}
                    className="mt-0 ml-0"
                    id="fullWidth"
                    onBlur={trueFalse}
                  >
                    <MenuItem value="Withdraw">Withdraw</MenuItem>
                    <MenuItem value="MT5">Transfer To MT5</MenuItem>
                  </Select>
                  {data.withdrawal_from == "" &&
                    infoTrue.withdrawal_from == true ? (
                    <FormHelperText>Select is required</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
                {data.withdrawal_from == "Withdraw" ? (
                  <>
                    <FormControl
                      className="w-100 "
                      error={data.payment_method == "" ? true : false}
                    >
                      {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
                      <label
                        htmlFor="transactionGateway"
                        className="text-info font-weight-bold form-label-head w-100 mt-4 required"
                      >
                        TRANSACTION GATEWAYS
                      </label>
                      <Select
                        value={data.payment_method}
                        name="payment_method"
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        input={<BootstrapInput />}
                        className="mt-0 ml-0"
                        id="fullWidth"
                        onBlur={trueFalse}
                      >
                        <MenuItem value="">Select Option </MenuItem>
                        {methodType.list.map((item) => {
                          return <MenuItem value={item}>{item} </MenuItem>;
                        })}
                      </Select>
                      {data.payment_method == "" &&
                        infoTrue.payment_method == true ? (
                        <FormHelperText>
                          Please Select Transaction Gateways
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    {menuItem()}
                  </>
                ) : (
                  ""
                )}
                {data.withdrawal_from == "MT5" ? (
                  <>
                    <FormControl
                      className="w-100 "
                      error={data.mt5_acc_no == "" ? true : false}
                    >
                      {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
                      <label
                        htmlFor="transactionGateway"
                        className="text-info font-weight-bold form-label-head w-100 mt-4 required"
                      >
                        MT5 Account
                      </label>

                      <Autocomplete
                        id="tags-standard"
                        value={data.mt5_acc_no}
                        options={methodType.mt5List}
                        // getOptionLabel={(option) => option.mt5_acc_no}
                        getOptionLabel={(option) =>
                          option
                            ? `${option.mt5_acc_no}(${option.is_self == true
                              ? `${option.ib_group_name}-Self`
                              : option.ib_group_name
                            })`
                            : ""
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.mt5_acc_no}>
                              {option.mt5_acc_no}(
                              {option.is_self == true
                                ? `${option.ib_group_name}-Self`
                                : option.ib_group_name}
                              )
                            </li>
                          );
                        }}
                        onChange={(event, newValue) => {
                          data.mt5_acc_no = newValue;
                          setData({ ...data });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="mt5_acc_no"
                            onBlur={trueFalse}
                            className="autoComplte-textfild mobileCode"
                            helperText={
                              (data.mt5_acc_no == "" ||
                                data.mt5_acc_no == null ||
                                data.mt5_acc_no == undefined) &&
                                infoTrue.mt5_acc_no == true
                                ? "Please Select MT5 Account"
                                : ""
                            }
                          />
                        )}
                      />

                    </FormControl>
                  </>
                ) : (
                  ""
                )}
                <FormControl
                  className="pb-4 w-100"
                  error={
                    data.amount == "" || data.amount > resData.balance
                      ? true
                      : false
                  }
                >
                  {/* <InputLabel htmlFor="account_no">ACCOUNT NO</InputLabel> */}
                  <label
                    htmlFor="amount"
                    className="text-info font-weight-bold form-label-head w-100 mt-4 required"
                  >
                    Amount In USD
                  </label>
                  <BootstrapInput
                    value={data.amount}
                    type="text"
                    name="amount"
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        handleChange(e);
                      }
                    }}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    onBlur={trueFalse}
                  />
                  {data.amount == "" && infoTrue.amount == true ? (
                    <FormHelperText>
                      Please Enter Amount In USD
                    </FormHelperText>
                  ) : data.amount > resData.balance &&
                    infoTrue.amount == true ? (
                    <FormHelperText>
                      Insufficient fund Balance.
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
              <Grid
                container
                spacing={2}
                className="MuiGrid-justify-xs-space-between mt-4 displayflxend"
              >
                {data.isLoder == true ? (
                  <ColorButton
                    tabindex="0"
                    size="large"
                    disabled
                    sx={{
                      padding: "20px 50px",
                      marginLeft: "10px",
                    }}
                  >
                    <svg class="spinner" viewBox="0 0 50 50">
                      <circle
                        class="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        stroke-width="5"
                      ></circle>
                    </svg>{" "}
                  </ColorButton>
                ) : (
                  <ColorButton
                    onClick={onsubmit}
                    variant="contained"
                    size="medium"
                    className=" text-capitalize "
                  >
                    Submit
                  </ColorButton>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IBDashboard;
